import "./App.css";
import { Main } from "./main/main";
import { Routes, Route } from "react-router-dom";
import { Invitation } from "./invitations/Invitation";
import { AllInvitations } from "./invitations/AllInvitations";
import { TakePhoto } from "./photos/take-photo";
import { DisplayPhotos } from "./photos/display-photos";
import { Gallery } from "./photos/gallery";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/inbjudan/:names" element={<Invitation />} />
      <Route path="/alla-inbjudningar/" element={<AllInvitations />} />
      <Route path="/ta-foto/" element={<TakePhoto />} />
      <Route path="/visa-foton/" element={<DisplayPhotos />} />
      <Route path="/galleri/" element={<Gallery />} />
    </Routes>
  );
}

export default App;
