import styled from "@emotion/styled";
import { useState } from "react";
import { keyframes } from "@emotion/react";
import { Sigil } from "./Sigil";

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  perspective: 1000px;
  cursor: pointer;
  ${({ className }) => className === "open" && `background: none;`}
  ${({ className }) => className === "open" && `pointer-events: none;`}
  text-shadow:
          1px 1px 2px #fff,
            -0.5px -0.5px 0px rgba(0, 0, 0, 0.2);
`;

const openLeftPage = keyframes`
    0% {
      transform: rotateY(0deg);
      //left: 0;
    }
    100% {
      transform: rotateY(-90deg);
      //left: -25%;
    }
`;

const openRightPage = keyframes`
  0% {
    transform: rotateY(0deg);
    //right: 0;
  }
  100% {
    transform: rotateY(90deg);
    //right: 25%;
  }
`;

const leftPageClosedBounce = keyframes`
  from, 30%, 75%, to {
    transform: RotateY(0deg);
  }

  60% {
    transform: RotateY(-10deg);
  }
  `;

const rightPageClosedBounce = keyframes`
  from, 30%, 75%, to {
    transform: RotateY(0deg);
  }

  60% {
    transform: RotateY(10deg);
  }
`;

const LeftPageClosed = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  background-image: linear-gradient(to bottom, #ffffff, #fcfaff);
  //border: 1px solid #ccc;
  pointer-events: none;
  transform-origin: left center;
  animation: ${leftPageClosedBounce} 6s ease infinite;
  overflow: hidden;
`;

const RightPageClosed = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 50%;
  background-image: linear-gradient(to bottom, #ffffff, #fcfaff);
  //border: 1px solid #ccc;
  pointer-events: none;
  transform-origin: right center;
  animation: ${rightPageClosedBounce} 6s ease infinite;
  overflow: hidden;
`;

const LeftPageOpen = styled(LeftPageClosed)`
  animation: ${openLeftPage} 3s ease-in-out forwards;
`;

const RightPageOpen = styled(RightPageClosed)`
  animation: ${openRightPage} 3s ease-in-out forwards;
`;

const LeftPageContentWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  font-family: "tomarik-extrovert", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #64b896;
  font-size: 21px;
`;

const RightPageContentWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-family: "tomarik-extrovert", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #64b896;
  font-size: 21px;
`;

const BorderLeft = styled.div`
  position: absolute;
  left: 35px;
  bottom: 35px;
  right: 0;
  top: 35px;
  border-style: solid;
  border-width: 10px;
  border-image-source: url("/borders.png");
  border-image-slice: 33%;
  border-image-repeat: round;
  border-right: none;
  pointer-events: none;
  z-index: 2;
`;

const BorderRight = styled.div`
  position: absolute;
  left: 0;
  bottom: 35px;
  right: 35px;
  top: 35px;
  border-style: solid;
  border-width: 10px;
  border-image-source: url("/borders.png");
  border-image-slice: 33%;
  border-image-repeat: round;
  border-left: none;
  pointer-events: none;
  z-index: 2;
`;

const LeftBorderBackground = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  width: 35px;
  background-color: #fcfbf2;
  z-index: 2;
`;

const RightBorderBackground = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  width: 35px;
  background-color: #fcfbf2;
  z-index: 2;
`;

const BottomBorderBackground = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 35px;
  background-color: #fcfbf2;
  z-index: 2;
`;

const TopBorderBackground = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 35px;
  background-color: #fcfbf2;
  z-index: 2;
`;

const OffsetLeftPageContent = styled.div`
  transform: translateX(50%);
  text-align: center;
`;
const OffsetRightPageContent = styled.div`
  transform: translateX(-50%);
  text-align: center;
`;

function Content({ personA, personB }) {
  return (
    <div>
      <Sigil style={{ width: 125 }} />
      <h2>Inbjudan</h2>
      <h4>
        {personA}
        {personB && " och " + personB}
      </h4>
      <h5>Tryck för att öppna</h5>
    </div>
  );
}

function LeftPageContent({ personA, personB }) {
  return (
    <LeftPageContentWrapper>
      <BorderLeft />
      <LeftBorderBackground />
      <BottomBorderBackground />
      <TopBorderBackground />
      <OffsetLeftPageContent>
        <Content personA={personA} personB={personB} />
      </OffsetLeftPageContent>
    </LeftPageContentWrapper>
  );
}
function RightPageContent({ personA, personB }) {
  return (
    <RightPageContentWrapper>
      <BorderRight />
      <RightBorderBackground />
      <BottomBorderBackground />
      <TopBorderBackground />
      <OffsetRightPageContent>
        <Content personA={personA} personB={personB} />
      </OffsetRightPageContent>
    </RightPageContentWrapper>
  );
}

function OverlayPages({ personA, personB, onOpen }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Wrapper
      className={isOpen ? "open" : ""}
      onClick={() => {
        setIsOpen(true);
        onOpen();
      }}
    >
      {isOpen ? (
        <>
          <LeftPageOpen>
            <LeftPageContent personA={personA} personB={personB} />
          </LeftPageOpen>
          <RightPageOpen>
            <RightPageContent personA={personA} personB={personB} />
          </RightPageOpen>
        </>
      ) : (
        <>
          <LeftPageClosed>
            <LeftPageContent personA={personA} personB={personB} />
          </LeftPageClosed>
          <RightPageClosed>
            <RightPageContent personA={personA} personB={personB} />
          </RightPageClosed>
        </>
      )}
    </Wrapper>
  );
}

export { OverlayPages };
