import { useParams } from "react-router";
import styled from "@emotion/styled";
import { InvitationTwoPeople } from "./components/InvitationTwoPeople";
import { InvitationOnePerson } from "./components/InvitationOnePerson";
import { Helmet } from "react-helmet";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  box-sizing: border-box;
  padding: 30px;
  text-align: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 30px;
  width: 100%;
  max-width: 550px;
  box-sizing: border-box;
`;

export const invitations = {
  // Vendelas

  "aminda-och-viktor": {
    component: InvitationTwoPeople,
    personA: "Aminda",
    fullNameA: "Aminda Adler",
    personB: "Viktor",
    fullNameB: "Viktor Sundström",
    invitation: null,
  },
  anna: {
    component: InvitationOnePerson,
    person: "Anna",
    fullName: "Anna Engelbert",
    invitation: null,
  },
  "cissi-och-jesper": {
    component: InvitationTwoPeople,
    personA: "Cissi",
    fullNameA: "Cissi Thorsén",
    personB: "Jesper",
    fullNameB: "Jesper Hansson",
    invitation: null,
  },
  david: {
    component: InvitationOnePerson,
    person: "David",
    fullName: "David Andersson",
    invitation: null,
  },
  "david-och-mathilda": {
    component: InvitationTwoPeople,
    personA: "David",
    fullNameA: "David Kilström",
    personB: "Mathilda",
    fullNameB: "Mathilda Fritzell",
    invitation: null,
  },
  "emma-och-zeljko": {
    component: InvitationTwoPeople,
    personA: "Emma",
    fullNameA: "Emma Cako Eliasson",
    personB: "Željko",
    fullNameB: "Željko Cako",
    invitation: null,
  },
  "elli-och-georg": {
    component: InvitationTwoPeople,
    personA: "Elli",
    fullNameA: "Elli Bäcklin Bergh",
    personB: "Georg",
    fullNameB: "Georg Källström",
    invitation: null,
  },
  fredrik: {
    component: InvitationOnePerson,
    person: "Fredrik",
    fullName: "Fredrik Robertsson",
    invitation: null,
  },
  "frida-och-jakob": {
    component: InvitationTwoPeople,
    personA: "Frida",
    fullNameA: "Frida Jonsson",
    personB: "Jakob",
    fullNameB: "Jakob Frida Jonssons kille",
    invitation: null,
  },
  "agnes-och-jonas": {
    component: InvitationTwoPeople,
    personA: "Agnes",
    fullNameA: "Agnes Silvergran",
    personB: "Jonas",
    fullNameB: "Jonas Lundqvist",
    invitation: null,
  },
  "kjell-och-susanna": {
    component: InvitationTwoPeople,
    personA: "Kjell",
    fullNameA: "Kjell Bergfeldt",
    personB: "Susanna",
    fullNameB: "Susanna Broms",
    invitation: null,
  },
  soren: {
    component: InvitationOnePerson,
    person: "Sören",
    fullName: "Sören Broms",
    invitation: null,
  },
  mimmi: {
    component: InvitationOnePerson,
    person: "Mimmi",
    fullName: "Mimmi Broms",
    invitation: null,
  },
  "nora-och-linus": {
    component: InvitationTwoPeople,
    personA: "Nora",
    fullNameA: "Nora Bergfeldt",
    personB: "Linus",
    fullNameB: "Linus Nilsson",
    invitation: null,
  },
  ivan: {
    component: InvitationOnePerson,
    person: "Ivan",
    fullName: "Ivan Milles",
    invitation: null,
  },
  "johanna-och-henrik": {
    component: InvitationTwoPeople,
    personA: "Johanna",
    fullNameA: "Johanna Samuelson",
    personB: "Henrik",
    fullNameB: "Henrik Olsen",
    invitation: null,
  },
  karin: {
    component: InvitationOnePerson,
    person: "Karin",
    fullName: "Karin Hartzell",
    invitation: null,
  },
  "lina-och-bjorn": {
    component: InvitationTwoPeople,
    personA: "Lina",
    fullNameA: "Lina Fridén",
    personB: "Björn",
    fullNameB: "Björn Fridén",
    invitation: null,
  },
  "lina-och-johan": {
    component: InvitationTwoPeople,
    personA: "Lina",
    fullNameA: "Lina Peterson",
    personB: "Johan",
    fullNameB: "Johan Rudling",
    invitation: null,
  },
  "lisa-och-max": {
    component: InvitationTwoPeople,
    personA: "Lisa",
    fullNameA: "Lisa Engstrand",
    personB: "Max",
    fullNameB: "Max Olofsson",
    invitation: null,
  },
  "minna-och-nico": {
    component: InvitationTwoPeople,
    personA: "Minna",
    fullNameA: "Minna Snickars",
    personB: "Nico",
    fullNameB: "Nico Minnas Snickars",
    invitation: null,
  },
  elin: {
    component: InvitationOnePerson,
    person: "Elin",
    fullName: "Elin Warfvinge",
    invitation: null,
  },
  "birgitta-pihlgren": {
    component: InvitationOnePerson,
    person: "Birgitta",
    fullName: "Birgitta Pihlgren",
    invitation: null,
  },
  "sandra-och-fredrik": {
    component: InvitationTwoPeople,
    personA: "Sandra",
    fullNameA: "Sandra Hedberg",
    personB: "Fredrik",
    fullNameB: "Fredrik Storck",
    invitation: null,
  },

  // Antons
  "simon-och-tiina": {
    component: InvitationTwoPeople,
    personA: "Simon",
    fullNameA: "Simon Deschamps",
    personB: "Tiina",
    fullNameB: "Tiina Lehtimäki",
    invitation: null,
  },
  "gabbe-och-rebecka": {
    component: InvitationTwoPeople,
    personA: "Gabbe",
    fullNameA: "Gabriel Riveros Vilches",
    personB: "Rebecka",
    fullNameB: "Rebecka Nilsson",
    invitation: null,
  },
  "zacaria-och-romina": {
    component: InvitationTwoPeople,
    personA: "Zacaria",
    fullNameA: "Zacaria Ghatan",
    personB: "Romina",
    fullNameB: "Romina Moradi",
    invitation: null,
  },
  vincent: {
    component: InvitationOnePerson,
    person: "Vincent",
    fullName: "Vincent Jonsson",
    invitation: null,
  },
  "fuji-och-sara": {
    component: InvitationTwoPeople,
    personA: "Fuji",
    fullNameA: "Fuji Hoffmann",
    personB: "Sara",
    fullNameB: "Sara Isaksson",
    invitation: null,
  },
  jenny: {
    component: InvitationOnePerson,
    person: "Jenny",
    fullName: "Jenny Öhman",
    invitation: null,
  },
  bosse: {
    component: InvitationOnePerson,
    person: "Bosse",
    fullName: "Bosse Persson",
    invitation: null,
  },
  "linus-och-sussi": {
    component: InvitationTwoPeople,
    personA: "Linus",
    fullNameA: "Linus Lejon",
    personB: "Sussi",
    fullNameB: "Sussi Lejon",
    invitation: null,
  },
  "ludvig-och-josefine": {
    component: InvitationTwoPeople,
    personA: "Ludvig",
    fullNameA: "Ludvig Lejon",
    personB: "Josefine",
    fullNameB: "Josefine Hedlöf",
    invitation: null,
  },
  "birgitta-ohman": {
    component: InvitationOnePerson,
    person: "Birgitta",
    fullName: "Birgitta Öhman",
    invitation: null,
  },
  "martin-och-katrine": {
    component: InvitationTwoPeople,
    personA: "Martin",
    fullNameA: "Martin Gæbe",
    personB: "Katrine",
    fullNameB: "Katrine Skovbo Jespersen",
    invitation: null,
  },
  "kristofer-och-linda": {
    component: InvitationTwoPeople,
    personA: "Kristofer",
    fullNameA: "Kristofer Dahlgren",
    personB: "Linda",
    fullNameB: "Linda Dahlgren",
    invitation: null,
  },
  "per-och-sanna": {
    component: InvitationTwoPeople,
    personA: "Per",
    fullNameA: "Per Sandberg",
    personB: "Sanna",
    fullNameB: "Sanna Wallin",
    invitation: null,
  },
  "rodrigo-och-zelda": {
    component: InvitationTwoPeople,
    personA: "Rodrigo",
    fullNameA: "Rodrigo Vilarinho",
    personB: "Zelda",
    fullNameB: "Zelda Soussan",
    invitation: null,
  },
  elias: {
    component: InvitationOnePerson,
    person: "Elias",
    fullName: "Elias Girod",
    invitation: null,
  },
  "ann-charlotte-och-johan": {
    component: InvitationTwoPeople,
    personA: "Ann-Charlotte",
    fullNameA: "Ann-Charlotte Pettersson",
    personB: "Johan",
    fullNameB: "Johan Jergelin",
    invitation: null,
  },
  "oriane-och-eve": {
    component: InvitationTwoPeople,
    personA: "Oriane",
    fullNameA: "Oriane Paras",
    personB: "Eve",
    fullNameB: "Eve Oriane Paras",
    invitation: null,
  },
  "alex-och-jilda": {
    component: InvitationTwoPeople,
    personA: "Alex",
    fullNameA: "Alex Nagy",
    personB: "Jilda",
    fullNameB: "Jilda Hallin",
    invitation: null,
  },
  "annu-och-eero": {
    component: InvitationTwoPeople,
    personA: "Annu",
    fullNameA: "Annu Ronkainen",
    personB: "Eero",
    fullNameB: "Eero Annu Ronkainen",
    invitation: null,
  },
  hilde: {
    component: InvitationOnePerson,
    person: "Hilde",
    fullName: "Hilde Sandvold",
    invitation: null,
  },
  "emma-och-tobias": {
    component: InvitationTwoPeople,
    personA: "Emma",
    fullNameA: "Emma Broady",
    personB: "Tobias",
    fullNameB: "Tobias Emma Broadys",
    invitation: null,
  },
  "elin-och-michael": {
    component: InvitationTwoPeople,
    personA: "Elin",
    fullNameA: "Elin Almén",
    personB: "Michael",
    fullNameB: "Michael Einhorn",
    invitation: null,
  },
  "natalie-och-erik": {
    component: InvitationTwoPeople,
    personA: "Natalie",
    fullNameA: "Natalie Minnevik",
    personB: "Erik",
    fullNameB: "Erik Natalie Minneviks",
    invitation: null,
  },

  // Test invitation
  "test-1-person": {
    component: InvitationOnePerson,
    person: "Test",
    fullName: "Test Testsson",
    invitation: null,
  },
  "test-2-people": {
    component: InvitationTwoPeople,
    personA: "Test1",
    fullNameA: "Test Testsson1",
    personB: "Test2",
    fullNameB: "Test Testsson2",
    invitation: null,
  },
};

function Invitation() {
  const { names } = useParams();
  const Component = invitations[names].component;

  return (
    <Wrapper>
      <Helmet>
        <title>
          Inbjudan{" "}
          {invitations[names].personA
            ? invitations[names].personA + " och " + invitations[names].personB
            : invitations[names].person}
        </title>
      </Helmet>
      <Content>
        <Component {...invitations[names]} />
      </Content>
    </Wrapper>
  );
}

export { Invitation };
