import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import useLocalStorage from "../use-localstorage";
import QRCode from "qrcode";
import axios from "axios";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 100%;
  max-width: 100%;
  padding: 30px;
  box-sizing: border-box;
  margin: 0 auto;
  height: 100%;
  font-size: 2rem;
  position: relative;
`;

const Grid = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  display: grid;
  grid-template-columns: 5fr 2fr;
  grid-template-rows: 1fr auto;
  gap: 20px;
  font-size: 1.6rem;
`;

const Photo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const PhotoInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

const Header = styled.h1`
  margin: 0;
  color: #002a54;
`;

const Paragraph = styled.p`
  margin: 0;
`;

const QRCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0px;
`;

export function DisplayPhotos() {
  const [tick, setTick] = useState(0); // eslint-disable-line no-unused-vars
  const [lastChangedAt, setLastChangedAt] = useState(0);
  const [photoId, setPhotoId] = useLocalStorage(0, "currentPhotoId");
  const [photoData, setPhotoData] = useState("");
  const [photoAuthor, setPhotoAuthor] = useLocalStorage(
    "",
    "currentPhotoAuthor",
  );
  const [numberOfPhotos, setNumberOfPhotos] = useState(0);
  const [minimumDisplayTime, setMinimumDisplayTime] = useLocalStorage(
    5_000,
    "minimumDisplayTime",
  );
  const [urlQrCodeImage, setUrlQrCodeImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const changePhoto = async (id) => {
    try {
      setIsLoading(true);
      const response = await axios.get("/get-photo.php?id=" + id);
      setPhotoId(id);
      setPhotoData(response.data.image);
      setPhotoAuthor(response.data.author);
      if (Date.now() - lastChangedAt > 10_000) {
        setMinimumDisplayTime(5_000);
      }
      setLastChangedAt(Date.now());
      return response;
    } catch (error) {
      console.error(error);
      return error;
    } finally {
      setIsLoading(false);
    }
  };

  const fetchNumberOfPhotos = async () => {
    try {
      const response = await axios.get("/number-of-photos.php");
      setNumberOfPhotos(response.data);
      return response;
    } catch (error) {
      console.error(error);
      return error;
    } finally {
      setTimeout(() => {
        fetchNumberOfPhotos();
      }, 2000);
    }
  };

  useEffect(() => {
    fetchNumberOfPhotos();
    setInterval(() => {
      setTick((tick) => tick + 1);
    }, 1000);
  }, []);

  useEffect(() => {
    const generateQRCode = async () => {
      try {
        const qrCodeImage = await QRCode.toDataURL(
          "https://bergslejon.se/ta-foto/",
          {
            color: {
              light: "#fffcfa",
            },
            width: 500,
          },
        );
        setUrlQrCodeImage(qrCodeImage);
      } catch (err) {
        console.error(err);
      }
    };

    generateQRCode();
  }, []);

  if (!isLoading && Date.now() >= lastChangedAt + minimumDisplayTime) {
    if (photoId < numberOfPhotos) {
      if (numberOfPhotos - photoId > 20) {
        setMinimumDisplayTime(1_000);
      }
      changePhoto(photoId + 1);
    } else if (photoData === "" && numberOfPhotos > 0) {
      changePhoto(photoId);
    }
  }

  return (
    <Grid>
      <Photo src={photoData} />
      <QRCodeContainer>
        <Header>Ta nästa foto</Header>
        <img src={urlQrCodeImage} alt="QR Code" width={"100%"} />
        <Paragraph>https://bergslejon.se/ta-foto/</Paragraph>
      </QRCodeContainer>
      <PhotoInfo>
        {photoAuthor && <Paragraph>Foto: {photoAuthor}</Paragraph>}
        <Paragraph>
          {photoId} av {numberOfPhotos}
        </Paragraph>
      </PhotoInfo>
    </Grid>
  );
}
