import { InfoSection } from "./InfoSection";
import { OverlayPages } from "./OverlayPages";
import { Sigil } from "./Sigil";
import { OsaSection } from "./OsaSection";
import { CommentSection } from "./CommentSection";
import { Names } from "./Names";
import { useEffect, useState } from "react";
import axios from "axios";
import styled from "@emotion/styled";
import { Paragraph } from "../../main/main";

const Wrapper = styled.div``;

function InvitationTwoPeople({
  personA,
  fullNameA,
  personB,
  fullNameB,
  invitation,
  shouldSkipOverlay = false,
}) {
  const [previousAnswers, setPreviousAnswers] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const fetchPreviousAnswers = async () => {
      try {
        const response = await axios.post(
          "/fetch.php",
          `name_1=${fullNameA}&name_2=${fullNameB}`,
        );
        setPreviousAnswers(response.data);
      } catch (error) {
        alert(
          "Någonting gick fel - säg till Vendela eller Anton så hjälper de dig!",
        );
      } finally {
        setIsLoading(false);
      }
    };
    fetchPreviousAnswers();
  }, []);

  return (
    <Wrapper>
      {!shouldSkipOverlay && (
        <>
          <OverlayPages
            personA={personA}
            personB={personB}
            onOpen={() => setIsOpen(true)}
          />
          <Sigil />
        </>
      )}
      <Names>
        {personA} och {personB}
      </Names>
      {!shouldSkipOverlay && (
        <>
          <p>
            Varmt välkomna till vårt bröllop på Färingsö den
            18:e&nbsp;maj&nbsp;2024!
          </p>
          {invitation}
          <InfoSection />
        </>
      )}
      <OsaSection
        personA={personA}
        name_1={fullNameA}
        personB={personB}
        name_2={fullNameB}
        previousAnswers={previousAnswers}
        isLoading={isLoading}
      />
      <CommentSection
        name_1={fullNameA}
        name_2={fullNameB}
        previousAnswers={previousAnswers}
        isLoading={isLoading}
      />
    </Wrapper>
  );
}

export { InvitationTwoPeople };
