import { useEffect, useState } from "react";
import axios from "axios";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import {
  BlommorLeftBottomMobile,
  BlommorRightBottomMobile,
  Border,
  BottomBorderBackground,
  BottomFlowersMobile,
  LeftBorderBackground,
  revealLeft,
  revealLeftMedium,
  revealLeftSmall,
  revealRight,
  revealRightMedium,
  revealRightSmall,
  RightBorderBackground,
} from "../main/main";
import styled from "@emotion/styled";

const Wrapper = styled.div`
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  padding: 30px 30px 100px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
  max-width: 800px;
  width: 100%;

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const PhotoContainer = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 8px;
`;

const Photo = styled.img`
  width: 100%;
  height: 256px;
  object-fit: cover;
  align-self: start;
  border-radius: 16px;

  @media (max-width: 768px) {
    height: 200px;
  }
`;

const PhotoInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
`;

const DateHeader = styled.h2`
  margin: 16px 0 -24px;
  font-size: 2.2rem;
`;

const Time = styled.h3`
  margin: 16px 0 8px;
  font-size: 1.8rem;
`;

const Author = styled.p`
  margin: 0;
  line-height: 1;
`;

const Header = styled.h1`
  font-size: 48px;
  margin: 60px 40px 40px;
`;

export const BlommorLeft = styled.img`
  transition: 1s;
  position: fixed;
  bottom: -25px;
  left: -65px;
  pointer-events: none;
  animation: ${revealLeft} 5s forwards;
  z-index: 3;
  animation-name: ${revealLeftMedium};
  @media (max-width: 1400px) {
    animation-name: ${revealLeftSmall};
  }
  @media (max-width: 767px) {
    // Mobile
    visibility: ${(props) =>
      props.scrollPosition > 10 || props.hasScrolled ? "hidden" : "visible"};
    transform: translate(-36px, 36px);
  }
`;

export const BlommorRight = styled.img`
  transition: 1s;
  position: fixed;
  bottom: -25px;
  right: -65px;
  transform: scaleX(-1);
  pointer-events: none;
  animation: ${revealRight} 5s forwards;
  z-index: 3;
  animation-name: ${revealRightMedium};
  @media (max-width: 1400px) {
    animation-name: ${revealRightSmall};
  }
  @media (max-width: 767px) {
    // Mobile
    visibility: ${(props) =>
      props.scrollPosition > 10 || props.hasScrolled ? "hidden" : "visible"};
    transform: translate(36px, 36px) scaleX(-1);
  }
`;

export function Gallery() {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [photos, setPhotos] = useState([]);

  const fetchAllPhotos = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("/all-photos.php");
      if (response.data === "Failed to fetch photos") setIsError(true);
      else setPhotos(response.data);
    } catch (error) {
      console.error(error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAllPhotos();
  }, []);

  if (isLoading) {
    return (
      <Wrapper>
        <Header>Fotogalleri</Header>
        <p>Laddar...</p>
      </Wrapper>
    );
  }

  if (isError) {
    return (
      <Wrapper>
        <Header>Fotogalleri</Header>
        <p>
          Något gick fel! Testa igen om en stund eller säg till Anton så kanske
          han kan försöka fixa det.
        </p>
      </Wrapper>
    );
  }

  const getDateNumberEnding = (dateNumber) => {
    if (
      dateNumber === "1" ||
      dateNumber === "2" ||
      dateNumber === "21" ||
      dateNumber === "22" ||
      dateNumber === "31"
    )
      return ":a";
    return ":e";
  };

  let lastDate = "";
  let lastHour = "";
  let mappedPhotos = {};

  photos.map((photo) => {
    const uploadedAt = new Date(photo.uploaded_at);

    const dateNumber = uploadedAt.toLocaleDateString("sv-SE", {
      day: "numeric",
    });
    const dateMonth = uploadedAt.toLocaleDateString("sv-SE", {
      month: "long",
    });
    const dateNumberEnding = getDateNumberEnding(dateNumber);
    const date = `${dateNumber}${dateNumberEnding} ${dateMonth}`;

    const hour = uploadedAt.toLocaleTimeString("sv-SE", {
      hour: "numeric",
    });

    const isNewDate = date !== lastDate;
    if (isNewDate) {
      lastDate = date;
      mappedPhotos[date] = {};
    }

    const isNewHour = hour !== lastHour;
    if (isNewHour) {
      lastHour = hour;
      mappedPhotos[date][hour] = [];
    }

    mappedPhotos[date][hour].push(photo);
  });

  return (
    <Wrapper>
      <Header>Fotogalleri</Header>
      <PhotoProvider>
        {Object.keys(mappedPhotos).map((date) => {
          return (
            <>
              <DateHeader>{date}</DateHeader>
              {Object.keys(mappedPhotos[date])
                .sort((a, b) => Number(a) > Number(b))
                .map((hour) => {
                  return (
                    <>
                      <Time>Klockan {hour}</Time>
                      <Grid>
                        {mappedPhotos[date][hour].map((photo) => {
                          const url = `/uploaded-photos/${photo.id}.${photo.extension}`;
                          return (
                            <PhotoContainer>
                              <PhotoView src={url}>
                                <Photo src={url} loading={"lazy"} />
                              </PhotoView>
                              <PhotoInfo>
                                {photo.author != false && (
                                  <Author>Foto: {photo.author}</Author>
                                )}
                              </PhotoInfo>
                            </PhotoContainer>
                          );
                        })}
                      </Grid>
                    </>
                  );
                })}
            </>
          );
        })}
      </PhotoProvider>

      <BottomFlowersMobile>
        <BlommorLeftBottomMobile src={"/blommor.png"} scrollPosition={11} />
        <BlommorRightBottomMobile src={"/blommor.png"} scrollPosition={11} />
      </BottomFlowersMobile>
      <Border />
      <LeftBorderBackground />
      <RightBorderBackground />
      <BottomBorderBackground />

      <BlommorLeft src={"/blommor.png"} scrollPosition={11} />
      <BlommorRight src={"/blommor.png"} scrollPosition={11} />
    </Wrapper>
  );
}
