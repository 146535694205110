import styled from "@emotion/styled";

const Text = styled.p`
  font-style: italic;
`;

const BottomParagraph = styled.p`
  margin-bottom: 36px;
`;

function InfoSection() {
  return (
    <>
      <p>
        All information finns <a href="/">här på&nbsp;bergslejon.se</a>
        <br />
        (vi heter ju Lejon och Bergfeldt)
      </p>
      <Text>Anton &amp; Vendela</Text>
      <BottomParagraph>
        <a href={"/ta-foto/"}>
          Tryck här för att ta ett foto som visas på projektorn!
        </a>
      </BottomParagraph>
    </>
  );
}

export { InfoSection };
