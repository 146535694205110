import { useState, useEffect } from "react";

function useLocalStorage(defaultValue, key) {
  // Get the existing value from localStorage or use the default value
  const stored = localStorage.getItem(key);
  const initial = stored ? JSON.parse(stored) : defaultValue;

  // Use useState to create the state variable
  const [value, setValue] = useState(initial);

  // Use useEffect to update localStorage when the state changes
  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
}

export default useLocalStorage;
