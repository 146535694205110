import { useParams } from "react-router";
import styled from "@emotion/styled";
import { invitations } from "./Invitation";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 30px;
  text-align: center;
  overflow-x: scroll;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 30px;
  width: 100%;
  max-width: 550px;
  box-sizing: border-box;
`;

function AllInvitations() {
  return (
    <Wrapper>
      {Object.keys(invitations).map((key) => {
        const invitation = invitations[key];
        const Component = invitation.component;
        return (
          <Content key={key}>
            <Component {...invitations[key]} shouldSkipOverlay={true} />
          </Content>
        );
      })}
    </Wrapper>
  );
}

export { AllInvitations };
