import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import axios from "axios";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  gap: 8px;
`;

const Heading = styled.h1`
  margin-bottom: 0;
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 100px;
  border-radius: 16px;
  padding: 16px;
  box-sizing: border-box;
`;

const Button = styled.button`
  //font-family: "tomarik-extrovert", sans-serif;
  z-index: 0;
  font-style: normal;
  color: #32226b;
  margin-top: 0;
  padding: 20px;
  width: 100%;
  border-radius: 16px;
  background-image: linear-gradient(to bottom right, #ffffff, #acdef5, #537cbc);
  box-shadow:
    inset -2px -4px 3px #32226b,
    inset 2px 2px 4px rgba(255, 255, 255, 0.8),
    0 4px 8px rgba(0, 0, 0, 0.2);
  border: none;
  outline: none;
  cursor: pointer;
  margin-bottom: 2px;
  :hover,
  :focus {
    box-shadow:
      inset -4px -6px 3px #32226b,
      inset 0px 0px 4px rgba(255, 255, 255, 0.8),
      4px 8px 8px rgba(0, 0, 0, 0.2);
    padding-bottom: 22px;
    padding-right: 22px;
    position: relative;
    top: -2px;
    left: -2px;
    margin-bottom: 0;
  }
  :active {
    box-shadow:
      inset 0px -2px 2px #32226b,
      inset 4px 4px 4px rgba(255, 255, 255, 0.1),
      1px 1px 4px rgba(0, 0, 0, 0.2);
    padding: 19px;
    top: 1px;
    left: 1px;
    margin-bottom: 1px;
  }
`;

function CommentSection({ name_1, name_2, previousAnswers, isLoading }) {
  const [comment, setComment] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [didSave, setDidSave] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      setComment(
        previousAnswers?.comment
          ? decodeURIComponent(previousAnswers.comment)
          : null,
      );
    }
  }, [isLoading]);

  if (isLoading) {
    return (
      <>
        <h1>Kommentar</h1>
        <p>Laddar...</p>
      </>
    );
  }
  return (
    <Wrapper>
      <Heading>Kommentar</Heading>
      <Textarea
        placeholder={"Här går det bra att skriva en kommentar."}
        disabled={isSaving}
        onChange={(event) => setComment(event.target.value)}
        value={comment ?? ""}
      />
      <Button
        disabled={isSaving}
        onClick={() => {
          setIsSaving(true);
          setDidSave(false);
          const post = async () => {
            try {
              await axios.post(
                "/comment.php",
                `name_1=${name_1}&name_2=${
                  name_2 ?? ""
                }&comment=${encodeURIComponent(comment)}`,
              );
              setDidSave(true);
            } catch (error) {
              alert(
                "Någonting gick fel - säg till Vendela eller Anton så hjälper de dig!",
              );
            } finally {
              setIsSaving(false);
            }
          };
          post();
        }}
      >
        {isSaving ? "Skickar..." : "Skicka"}
      </Button>
      {didSave && <p>Skickat 🐋</p>}
    </Wrapper>
  );
}

export { CommentSection };
