import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

const Headline = styled.h2`
  font-size: 30px;
  letter-spacing: -0.08em;
  opacity: ${(props) => (props.scrollPosition > 10 ? 1 : 0)};
  transition: 1s;
  margin-top: 30px;
  margin-bottom: 6px;
`;

export const Paragraph = styled.p`
  opacity: ${(props) => (props.scrollPosition > 10 ? 1 : 0)};
  transition: 1s;
  margin: 0;
  margin-bottom: 10px;
  line-height: 1.25em;
`;

const List = styled.ul`
  opacity: ${(props) => (props.scrollPosition > 10 ? 1 : 0)};
  transition: 1s;
`;

const OrderedList = styled.ol`
  opacity: ${(props) => (props.scrollPosition > 10 ? 1 : 0)};
  transition: 1s;
  margin: 0;
  margin-bottom: 10px;
`;

const ListItem = styled.li`
  transition: 1s;
  margin-bottom: 2px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const Content = styled.div`
  margin-top: 570px;
  margin-bottom: 110px;
  width: 100%;
  max-width: 450px;
  @media (min-height: 1000px) {
    margin-top: 630px;
  }
  @media (max-height: 800px) {
    margin-top: 370px;
  }
  @media (max-height: 600px) {
    margin-top: 270px;
  }
  @media (max-width: 1460px) {
    // Tablet
    max-width: 400px;
  }
  @media (max-width: 1023px) {
    // Tablet
    max-width: 350px;
  }
  @media (max-width: 767px) {
    // Mobile
    max-width: 300px;
    margin-bottom: 30px;
  }
`;

const Background = styled.div`
  position: fixed;
  left: 35px;
  bottom: 35px;
  right: 35px;
  top: 0;
  background-color: #fffcfa;
  pointer-events: none;
  z-index: -1;
  @media (max-width: 767px) {
    // Mobile
    display: none;
  }
`;

export const Border = styled.div`
  position: fixed;
  left: 35px;
  bottom: 35px;
  right: 35px;
  top: 0;
  border-style: solid;
  border-width: 10px;
  border-image-source: url("/borders.png");
  border-image-slice: 33%;
  border-image-repeat: round;
  border-top: none;
  pointer-events: none;
  z-index: 2;
  @media (max-width: 767px) {
    // Mobile
    display: none;
  }
`;

export const LeftBorderBackground = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0;
  width: 35px;
  background-color: #fcfbf2;
  z-index: 2;
  @media (max-width: 767px) {
    // Mobile
    display: none;
  }
`;

export const RightBorderBackground = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  width: 35px;
  background-color: #fcfbf2;
  z-index: 2;
  @media (max-width: 767px) {
    // Mobile
    display: none;
  }
`;

export const BottomBorderBackground = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  height: 35px;
  background-color: #fcfbf2;
  z-index: 2;
  @media (max-width: 767px) {
    // Mobile
    display: none;
  }
`;

export const revealLeft = keyframes`
  from {
    left: -65px;
    bottom: -25px;
    width: 1005px;
  }
  to {
    left: -10px;
    bottom: 10px;
    width: 502.5px;
  }
`;

export const revealRight = keyframes`
  from {
    right: -65px;
    bottom: -25px;
    width: 1005px;
  }
  to {
    right: -10px;
    bottom: 10px;
    width: 502.5px;
  }
`;

export const revealLeftMedium = keyframes`
  from {
    left: -20px;
    bottom: 4px;
    width: 605px;
  }
  to {
    left: 12px;
    bottom: 24px;
    width: 300px;
  }
`;

export const revealRightMedium = keyframes`
  from {
    right: -20px;
    bottom: 4px;
    width: 605px;
  }
  to {
    right: 12px;
    bottom: 24px;
    width: 300px;
  }
`;

export const revealLeftSmall = keyframes`
  from {
    left: -20px;
    bottom: 4px;
    width: 605px;
  }
  to {
    left: 12px;
    bottom: 24px;
    width: 170px;
  }
`;

export const revealRightSmall = keyframes`
  from {
    right: -20px;
    bottom: 4px;
    width: 605px;
  }
  to {
    right: 12px;
    bottom: 24px;
    width: 170px;
  }
`;

export const BlommorLeft = styled.img`
  transition: 1s;
  position: fixed;
  bottom: -25px;
  left: -65px;
  pointer-events: none;
  animation: ${revealLeft} 5s forwards;
  z-index: 3;
  @media (max-width: 1460px) {
    animation-name: ${revealLeftMedium};
  }
  @media (max-width: 1023px) {
    animation-name: ${revealLeftSmall};
  }
  @media (max-width: 767px) {
    // Mobile
    visibility: ${(props) =>
      props.scrollPosition > 10 || props.hasScrolled ? "hidden" : "visible"};
    transform: translate(-36px, 36px);
  }
`;

export const BlommorRight = styled.img`
  transition: 1s;
  position: fixed;
  bottom: -25px;
  right: -65px;
  transform: scaleX(-1);
  pointer-events: none;
  animation: ${revealRight} 5s forwards;
  z-index: 3;
  @media (max-width: 1460px) {
    animation-name: ${revealRightMedium};
  }
  @media (max-width: 1023px) {
    animation-name: ${revealRightSmall};
  }
  @media (max-width: 767px) {
    // Mobile
    visibility: ${(props) =>
      props.scrollPosition > 10 || props.hasScrolled ? "hidden" : "visible"};
    transform: translate(36px, 36px) scaleX(-1);
  }
`;

export const BottomFlowersMobile = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  overflow: hidden;
  @media (min-width: 768px) {
    display: none;
  }
`;

export const BlommorLeftBottomMobile = styled.img`
  width: 170px;
  position: relative;
  left: -17px;
  bottom: -12px;
`;

export const BlommorRightBottomMobile = styled.img`
  width: 170px;
  transform: scaleX(-1);
  position: relative;
  right: -17px;
  bottom: -12px;
`;

const swimIn = keyframes`
  from {
    left: 100px;
    top: -50px;
    rotate: -10deg;
    scale: 0.9;
  }
  to {
    left: 0;
    top: 0;
    rotate: -5deg;
    scale: 1;
  }
`;

const WhaleContainerOverflowHandler = styled.div`
  overflow-x: hidden;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  pointer-events: none;
  z-index: 1;
`;

const WhaleContainer = styled.div`
  position: relative;
  left: calc(50% - 220px - ${(props) => props.scrollPosition / 2}px);
  top: ${(props) => props.scrollPosition / 4 - 20}px;
  rotate: ${(props) => props.scrollPosition / 60 - 5}deg;
  scale: ${(props) => 1 - props.scrollPosition / 3000};
  @media (max-height: 800px) {
    top: ${(props) => props.scrollPosition / 4 - 200}px;
  }
  @media (max-height: 600px) {
    top: ${(props) => props.scrollPosition / 4 - 300}px;
  }
`;

const Whale = styled.img`
  position: relative;
  animation: ${swimIn} ease-out 5s forwards;
`;

const SecondWhaleContainer = styled.div`
  position: absolute;
  display: ${(props) => (props.scrollPosition > 725 ? "block" : "none")};
  left: calc(50% - 1070px + ${(props) => props.scrollPosition / 9}px);
  top: ${(props) => props.scrollPosition * 1.55 - 620}px;
  scale: ${(props) => 0.9 - props.scrollPosition / 10000};
  transform: scaleY(-1);
  z-index: 1;
  @media (max-width: 767px) {
    display: none;
  }
`;

const SecondWhale = styled.img`
  position: absolute;
  transform: rotate(${(props) => props.scrollPosition * 0.092}deg);
`;

const appear = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const Title = styled.h1`
  font-size: 36px;
  text-align: center;
  margin-bottom: 50px;
  animation: ${appear} 2s forwards;
`;

const appearLate = keyframes`
  from {
    opacity: 0;
  }
  83% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const ScrollDownIndicator = styled.button`
  position: fixed;
  bottom: 100px;
  left: calc(50% - 75px);
  width: 150px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 20px;
  color: #64b896;
  display: ${(props) =>
    props.scrollPosition > 10 || props.hasScrolled ? "none" : "block"};
  transition: 1s;
  cursor: pointer;
  z-index: 2;
  animation: ${appearLate} 7s;
  background: none;
  border: none;
  @media (max-width: 767px) {
    position: relative;
    bottom: auto;
    left: auto;
    width: 100%;
  }
`;

const Spacer = styled.div`
  display: block;
  margin-bottom: 30px;
`;

function Main() {
  const [scrollPosition, setScrollPosition] = useState(window.scrollY);
  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      setScrollPosition(window.scrollY);
      if (window.scrollY >= 10) {
        setHasScrolled(true);
      }
    };
    document.addEventListener("scroll", onScroll);
    return () => {
      document.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <Wrapper>
      <Helmet>
        <title>Vendelas & Antons bröllop</title>
      </Helmet>
      <WhaleContainerOverflowHandler>
        <WhaleContainer scrollPosition={scrollPosition}>
          <Whale src={"/val.png"} scrollPosition={scrollPosition} />
        </WhaleContainer>
      </WhaleContainerOverflowHandler>
      <Content>
        <Title scrollPosition={scrollPosition}>
          Vendelas & Antons
          <br />
          Bröllop
        </Title>

        <ScrollDownIndicator
          scrollPosition={scrollPosition}
          hasScrolled={hasScrolled}
          onClick={() => {
            window.scrollTo({ top: 310, behavior: "smooth" });
          }}
        >
          Skrolla ned
        </ScrollDownIndicator>

        <Paragraph scrollPosition={scrollPosition}>
          Varmt välkommen till vår bröllopsfest den 18:e maj i Ölsta Folkets hus
          och park på Färingsö.{" "}
          <a
            href={"https://maps.app.goo.gl/SAnWego6EoEtMoEVA"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            Det ligger här.
          </a>
        </Paragraph>

        <Headline scrollPosition={scrollPosition}>Ta ett foto</Headline>
        <Paragraph scrollPosition={scrollPosition}>
          <a href={"/ta-foto/"}>
            Tryck här för att ta ett foto som visas på projektorn!
          </a>
        </Paragraph>
        <Paragraph scrollPosition={scrollPosition}>
          <a href={"/galleri/"}>Eller tryck här för att se alla bilder.</a>
        </Paragraph>

        <Headline scrollPosition={scrollPosition}>Varför en fest?</Headline>
        <Paragraph scrollPosition={scrollPosition}>
          Vi har noga pratat igenom varför vi vill gifta oss och varför vi vill
          att du ska vara med, och vi har kokat ner det till de här tre
          punkterna:
        </Paragraph>
        <OrderedList scrollPosition={scrollPosition}>
          <ListItem>För att fira vår kärlek</ListItem>
          <ListItem>
            För att ge tillbaka och tacka er som hjälpt oss nå dit vi är i dag
          </ListItem>
          <ListItem>För att foga samman våra familjer</ListItem>
        </OrderedList>

        <Headline scrollPosition={scrollPosition}>När börjar det?</Headline>
        <Paragraph scrollPosition={scrollPosition}>
          Festligheterna börjar strax efter klockan 15 (synkat med SL-bussen).
        </Paragraph>

        <Headline scrollPosition={scrollPosition}>
          Vad kommer att hända?
        </Headline>
        <Paragraph scrollPosition={scrollPosition}>
          Vi kommer att inleda med en liten ceremoni, och avsluta med dans till
          Antons mammas band. Däremellan bjuder vi på middag som blir en
          vegetarisk &amp; vegansk buffé. Hör av dig om du behöver specialkost.
        </Paragraph>

        <Headline scrollPosition={scrollPosition}>
          Händer det något annat före?
        </Headline>
        <Paragraph scrollPosition={scrollPosition}>
          För att kunna fira med så många som möjligt har vi valt att gifta oss
          lördagen innan. Då blir det främst för släkt, barn och om nån hemskt
          gärna vill fira två gånger. Det händer alltså ingenting innan
          bröllopsfesten, utan det räcker att komma till klockan&nbsp;15. Vi
          kommer att ha en liten ceremoni då, så det räcker fint att komma till
          den!
        </Paragraph>

        <Headline scrollPosition={scrollPosition}>När slutar det?</Headline>
        <Paragraph scrollPosition={scrollPosition}>
          Festen slutar with a bang klockan 01:00 och vi har abonnerat en
          direktbuss som tar alla som vill till Fridhemsplan och T-Centralen i
          anslutning till det.
        </Paragraph>

        <Headline scrollPosition={scrollPosition}>
          Vad ska jag ha på mig?
        </Headline>
        <Paragraph scrollPosition={scrollPosition}>
          Klädsel: Kavaj (eller det du känner dig fin i)
        </Paragraph>

        <Headline scrollPosition={scrollPosition}>
          Vad ska jag ha med mig?
        </Headline>
        <Paragraph scrollPosition={scrollPosition}>
          Till middagen blir det vingalleri, så ta med{" "}
          <strong>en flaska valfritt vin</strong> att ställa till samlingen att
          välja ifrån. Vi ordnar alkoholfria alternativ till den som vill ha
          det.
        </Paragraph>
        <Paragraph scrollPosition={scrollPosition}>
          En fin present för oss vore att du kommer och stannar hela kvällen.
        </Paragraph>
        <Paragraph scrollPosition={scrollPosition}>
          Festen är barnfri, men spädisar får så klart följa med om ni känner
          att det behövs.
        </Paragraph>

        <Headline scrollPosition={scrollPosition}>Hitta hit</Headline>
        <Paragraph scrollPosition={scrollPosition}>
          Buss 176 mot Stenhamra avgår från Brommaplan klockan 13:38 och 13:58.
          Byte i Stenhamra Centrum till buss 317 mot Björkvik klockan 14:40.
          Kliv av på hållplats Folkets hus, Ölsta. <strong>OBS!</strong> Buss
          317 går extremt sällan, så det är viktigt att vara i tid.
        </Paragraph>
        <Paragraph scrollPosition={scrollPosition}>
          Festen börjar när bussen har kommit fram.
        </Paragraph>
        <Paragraph scrollPosition={scrollPosition}>
          <a
            href={
              "https://sl.se/?mode=travelPlanner&origName=Brommaplan+(Stockholm)&origSiteId=9109&origPlaceId=QT0xQE89QnJvbW1hcGxhbiAoU3RvY2tob2xtKUBYPTE3OTM3ODY3QFk9NTkzMzgyOThAVT03NEBMPTMwMDEwOTEwOUBCPTFA&destName=Folkets+hus%2C+%C3%96lsta+(Eker%C3%B6)&destPlaceId=QT0xQE89Rm9sa2V0cyBodXMsIMOWbHN0YSAoRWtlcsO2KUBYPTE3NjU2NzIwQFk9NTk0MDMzMTdAVT03NEBMPTMwMDEwMzMyNUBCPTFA&datetime=2024-05-18T15%3A10&searchForArrival=true"
            }
            target={"_blank"}
            rel={"noreferrer"}
          >
            Bussavgångar från Brommaplan
          </a>
        </Paragraph>
        <Paragraph scrollPosition={scrollPosition}>
          På hemvägen finns som sagt en abonnerad buss som kommer att köra alla
          som vill till T-Centralen via Fridhemsplan efter avslutningen klockan
          01:00.
        </Paragraph>
        <Paragraph scrollPosition={scrollPosition}>
          Parkering finns. Om du tänkte komma med bil och har plats över till
          liftare får du gärna höra av dig till oss.
        </Paragraph>

        <Headline scrollPosition={scrollPosition}>Bo här</Headline>
        <Paragraph scrollPosition={scrollPosition}>
          Om du vill bo kvar på Färingsö finns dessa alternativ:
        </Paragraph>
        <List scrollPosition={scrollPosition}>
          <ListItem scrollPosition={scrollPosition}>
            <a
              href={"http://www.lillasorgarden.se/"}
              target={"_blank"}
              rel={"noreferrer"}
            >
              Lilla sörgården
            </a>{" "}
            (lite enklare, ca. 2½ km från festen). Vi har bokat 2 hus med totalt
            14 bäddar i 6 rum. Kontakta oss om ni vill bo där.
          </ListItem>
          <ListItem scrollPosition={scrollPosition}>
            <a
              href={"https://www.sanga-saby.se/hotell/"}
              target={"_blank"}
              rel={"noreferrer"}
            >
              Sånga säby
            </a>{" "}
            (lite finare, ca. 5 km från festen)
          </ListItem>
        </List>
        <Paragraph scrollPosition={scrollPosition}>
          Slutbussen passerar också båda dessa boenden.
        </Paragraph>

        <Headline scrollPosition={scrollPosition}>OSA</Headline>
        <Paragraph scrollPosition={scrollPosition}>
          Senast den 10:e mars på din inbjudningslänk.
        </Paragraph>

        <Headline scrollPosition={scrollPosition}>Tal och annat</Headline>
        <Paragraph scrollPosition={scrollPosition}>
          Om du vill hålla tal eller vara i rampljuset av någon annan anledning
          under middagen, kontakta toastmaster Gabbe på{" "}
          <a href="mailto:gabriel.rosvil@gmail.com?subject=Tal på Vendelas och Antons bröllop">
            gabriel.rosvil@gmail.com
          </a>
          .
        </Paragraph>

        <Headline scrollPosition={scrollPosition}>
          Om något händer under dagen
        </Headline>
        <Paragraph scrollPosition={scrollPosition}>
          Om du missar bussen eller något annat händer som gör att du behöver
          ringa nån kan du ringa till Nora på{" "}
          <a href="tel:0721777499">072-177 74 99</a>.
        </Paragraph>
        <Spacer />
        <Paragraph scrollPosition={scrollPosition}>
          Än en gång varmt välkommen!
        </Paragraph>
      </Content>
      <BottomFlowersMobile>
        <BlommorLeftBottomMobile
          src={"/blommor.png"}
          scrollPosition={scrollPosition}
        />
        <BlommorRightBottomMobile
          src={"/blommor.png"}
          scrollPosition={scrollPosition}
        />
      </BottomFlowersMobile>
      <Background />
      <Border />
      <LeftBorderBackground />
      <RightBorderBackground />
      <BottomBorderBackground />
      <SecondWhaleContainer scrollPosition={scrollPosition}>
        <SecondWhale src={"/val.png"} scrollPosition={scrollPosition} />
      </SecondWhaleContainer>
      <BlommorLeft src={"/blommor.png"} scrollPosition={scrollPosition} />
      <BlommorRight src={"/blommor.png"} scrollPosition={scrollPosition} />
    </Wrapper>
  );
}

export { Main };
