import styled from "@emotion/styled";
import useLocalStorage from "../use-localstorage";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { upload } from "@testing-library/user-event/dist/upload";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 100%;
  max-width: 800px;
  padding: 30px;
  box-sizing: border-box;
  margin: 0 auto;
  gap: 12px;
`;

const Header = styled.h1`
  margin: 0;
`;

const Paragraph = styled.p`
  margin: 0;
`;

const PhotoContainer = styled.div`
  width: 100%;
  aspect-ratio: 1 / 1;
  background: #64b896;
  color: #fff;
  border-radius: 30%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  font-size: 20px;
`;

const PhotoPreview = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 30%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`;

const PhotoProgress = styled.div`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 30%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
  background: conic-gradient(
    rgba(100, 184, 150, 0.8) 0%,
    rgba(100, 184, 150, 0.8) ${(props) => props.progress}%,
    rgba(100, 184, 150, 0) ${(props) => props.progress}%
  );
`;

const PhotoInput = styled.input`
  display: none;
`;

const PhotoInputLabel = styled.label`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const AuthorName = styled.input`
  padding: 10px 15px 7px;
  border-radius: 10px;
  border: 1px solid #ccc;
  width: 80%;
  box-sizing: border-box;
`;

const Button = styled.button`
  padding: 7px 12px 4px;
  border-radius: 10px;
  border: 2px solid #000;
  box-sizing: border-box;
  background: ${(props) => (props.className === "yes" ? "#64b896" : "#fff")};
  color: ${(props) => (props.className === "yes" ? "#fff" : "#000")};
  font-size: 16px;

  &:hover {
    background: ${(props) => (props.className === "yes" ? "#4a8e72" : "#eee")};
  }

  &:active {
    background: ${(props) => (props.className === "yes" ? "#3a6e59" : "#ddd")};
  }
`;

export function TakePhoto() {
  const [name, setName] = useLocalStorage("", "name");
  const [deviceId, setDeviceId] = useLocalStorage(uuidv4, "deviceId");
  const [currentCamera, setCurrentCamera] = useLocalStorage(0, "currentCamera");
  const [currentCameraFacing, setCurrentCameraFacing] = useLocalStorage(
    "environment",
    "currentCameraFacing",
  );
  const [photo, setPhoto] = useState(null);
  const [photoPreview, setPhotoPreview] = useState(null);
  const [progress, setProgress] = useState(0);
  const [uploadCancellationToken, setUploadCancellationToken] = useState(null);

  const hasTakenPhoto = photo !== null && progress < 100;

  return (
    <Wrapper>
      <Header>Ta ett foto</Header>
      <Paragraph>
        Fotot kommer att visas på projektorn och skickas till Vendela & Anton.
      </Paragraph>
      <AuthorName
        placeholder={"Skriv ditt namn (om du vill)"}
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <PhotoContainer>
        {hasTakenPhoto ? (
          <>
            <PhotoPreview src={photoPreview} />
            <PhotoProgress progress={progress} />
            <Paragraph
              style={{
                zIndex: 3,
                background: "rgba(0,0,0,0.9)",
                padding: "10px 20px 7px",
                borderRadius: 10,
              }}
            >
              Skickar...
            </Paragraph>
            <Button
              onClick={() => {
                uploadCancellationToken.cancel("Operation cancelled by user");
                setPhoto(null);
                setPhotoPreview(null);
                setProgress(0);
              }}
              style={{ zIndex: 3 }}
            >
              Avbryt
            </Button>
          </>
        ) : (
          <>
            <PhotoInput
              type="file"
              accept="image/*"
              capture="currentCameraFacing"
              onChange={(e) => {
                const post = async (file, cancellationToken) => {
                  try {
                    const formData = new FormData();
                    formData.append("author", name);
                    formData.append("deviceId", deviceId);
                    formData.append("photo", file);

                    await axios.post("/upload-photo.php", formData, {
                      onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round(
                          (progressEvent.loaded * 100) / progressEvent.total,
                        );
                        setProgress(percentCompleted);
                      },
                      headers: {
                        "Content-Type": "multipart/form-data",
                      },
                      cancelToken: cancellationToken.token,
                    });
                  } catch (error) {
                    if (error.code != "ERR_CANCELED") {
                      alert(
                        "Någonting gick fel - säg till Vendela eller Anton så hjälper de dig!",
                      );
                    }
                    setProgress(0);
                  } finally {
                    setPhoto(null);
                    setPhotoPreview(null);
                    setUploadCancellationToken(null);
                  }
                };

                const file = e.target.files[0];
                const cancellationToken = axios.CancelToken.source();

                if (file) {
                  setPhoto(e.target.files[0]);
                  const photoUrl = URL.createObjectURL(file);
                  setPhotoPreview(photoUrl);
                  setProgress(0);
                  setUploadCancellationToken(cancellationToken);
                  post(file, cancellationToken);
                }
              }}
              id="photoInput"
              name="photoInput"
            />
            <PhotoInputLabel htmlFor="photoInput">
              {progress == 100 ? (
                <>
                  <Paragraph>Skickat!</Paragraph>
                  Tryck här för att
                  <br />
                  ta ett nytt foto
                </>
              ) : (
                <>
                  Tryck här för att
                  <br />
                  öppna kameran
                </>
              )}
            </PhotoInputLabel>
          </>
        )}
      </PhotoContainer>
      <Paragraph>Skicka så många du vill!</Paragraph>
    </Wrapper>
  );
}
