import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { keyframes } from "@emotion/react";
import axios from "axios";

const Header = styled.h1`
  //margin-bottom: 4px;
`;

const PreloadImage = styled.img`
  width: 0;
  height: 0;
`;

const Grid = styled.div`
  display: grid;
  gap: 60px;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 40px;
`;

const ButtonWrapper = styled.div`
  position: relative;
`;

const GrowAnimation = keyframes`
  0% {
    transform: translate(-50%, -50%) scale(0);
  }
  100% {
    transform: translate(-50%, -50%) scale(0.4);
  }
`;

const Flowers = styled.img`
  position: absolute;
  z-index: -1;
  left: 50%;
  top: 50%;
  animation: ${GrowAnimation} 1s cubic-bezier(0.2, 0.8, 0.8, 0.95) forwards;
`;

const Button = styled.button`
  //font-family: "tomarik-extrovert", sans-serif;
  z-index: 0;
  font-style: normal;
  color: #32226b;
  margin-top: 0;
  padding: 20px;
  width: 112px;
  border-radius: 50%;
  background-image: linear-gradient(to bottom right, #ffffff, #acdef5, #537cbc);
  box-shadow:
    inset -2px -4px 3px #32226b,
    inset 2px 2px 4px rgba(255, 255, 255, 0.8),
    0 4px 8px rgba(0, 0, 0, 0.2);
  border: none;
  outline: none;
  aspect-ratio: 1/1;
  cursor: pointer;
  margin-bottom: 2px;
  :hover,
  :focus {
    box-shadow:
      inset -4px -6px 3px #32226b,
      inset 0px 0px 4px rgba(255, 255, 255, 0.8),
      4px 8px 8px rgba(0, 0, 0, 0.2);
    padding-bottom: 22px;
    padding-right: 22px;
    position: relative;
    top: -2px;
    left: -2px;
    margin-bottom: 0;
  }
  :active {
    box-shadow:
      inset 0px -2px 2px #32226b,
      inset 4px 4px 4px rgba(255, 255, 255, 0.1),
      1px 1px 4px rgba(0, 0, 0, 0.2);
    padding: 19px;
    top: 1px;
    left: 1px;
    margin-bottom: 1px;
  }
`;

const WhiteButton = styled(Button)`
  background-image: linear-gradient(to bottom right, #ffffff, #eee, #ccc);
`;

const RedButton = styled(Button)`
  background-image: linear-gradient(to bottom right, #ffffff, #cdacf5, #7153bc);
`;

function OsaButtonYes({ fullName, selected, onClick }) {
  const [isLoading, setIsLoading] = useState(false);
  const Component = selected ? Button : WhiteButton;
  const [shouldDisplayLoadingText, setShouldDisplayLoadingText] =
    useState(false);

  const onLongLoad = () => {
    setShouldDisplayLoadingText(true);
  };
  if (!isLoading && shouldDisplayLoadingText) {
    setShouldDisplayLoadingText(false);
  }

  return (
    <ButtonWrapper>
      <Component
        disabled={isLoading}
        onClick={() => {
          setIsLoading(true);
          const post = async () => {
            try {
              setTimeout(onLongLoad, 500);
              await axios.post("/coming.php", `name=${fullName}`);
              onClick();
            } catch (error) {
              alert(
                "Någonting gick fel - säg till Vendela eller Anton så hjälper de dig!",
              );
            } finally {
              setIsLoading(false);
            }
          };
          post();
        }}
      >
        {shouldDisplayLoadingText ? "Sparar..." : "Kommer"}
      </Component>
      {selected && <Flowers src={"/syren-cirkel.png"} />}
    </ButtonWrapper>
  );
}

function OsaButtonNo({ fullName, selected, onClick }) {
  const [isLoading, setIsLoading] = useState(false);
  const Component = selected ? RedButton : WhiteButton;
  const [shouldDisplayLoadingText, setShouldDisplayLoadingText] =
    useState(false);

  const onLongLoad = () => {
    setShouldDisplayLoadingText(true);
  };
  if (!isLoading && shouldDisplayLoadingText) {
    setShouldDisplayLoadingText(false);
  }

  return (
    <ButtonWrapper>
      <Component
        disabled={isLoading}
        onClick={() => {
          setIsLoading(true);
          const post = async () => {
            try {
              setTimeout(onLongLoad, 500);
              await axios.post("/not-coming.php", `name=${fullName}`);
              onClick();
            } catch (error) {
              alert(
                "Någonting gick fel - säg till Vendela eller Anton så hjälper de dig!",
              );
            } finally {
              setIsLoading(false);
            }
          };
          post();
        }}
      >
        {shouldDisplayLoadingText ? "Sparar..." : "Kan inte"}
      </Component>
      {selected && <Flowers src={"/syren-cirkel.png"} />}
    </ButtonWrapper>
  );
}

function OsaSection({
  personA,
  name_1,
  personB,
  name_2,
  previousAnswers,
  isLoading,
}) {
  const [personAAnswer, setPersonAAnswer] = useState(null);
  const [personBAnswer, setPersonBAnswer] = useState(null);

  useEffect(() => {
    if (!isLoading) {
      setPersonAAnswer(previousAnswers?.osa_1 ?? null);
      setPersonBAnswer(previousAnswers?.osa_2 ?? null);
    }
  }, [isLoading]);

  if (isLoading) {
    return (
      <>
        <Header>OSA</Header>
        <p>Laddar...</p>
      </>
    );
  }
  return (
    <>
      <PreloadImage src={"/syren-cirkel.png"} />
      <Header>OSA</Header>
      {personB && <h2>{personA}</h2>}
      <Grid>
        <OsaButtonYes
          fullName={name_1}
          selected={personAAnswer}
          onClick={() => setPersonAAnswer(true)}
        />
        <OsaButtonNo
          fullName={name_1}
          selected={personAAnswer === false}
          onClick={() => setPersonAAnswer(false)}
        />
      </Grid>
      {personB && (
        <>
          <h2>{personB}</h2>
          <Grid>
            <OsaButtonYes
              fullName={name_2}
              selected={personBAnswer}
              onClick={() => setPersonBAnswer(true)}
            />
            <OsaButtonNo
              fullName={name_2}
              selected={personBAnswer === false}
              onClick={() => setPersonBAnswer(false)}
            />
          </Grid>
        </>
      )}
    </>
  );
}

export { OsaSection };
